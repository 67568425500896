var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"sort-by":"name","items-per-page":20,"footer-props":{
                'items-per-page-options': [10, 20, 50, 100, 200],
                'show-current-page': true,
                'show-first-last-page': true,
            }},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
            var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"36"}},[_c('img',{attrs:{"src":item.avatar,"alt":item.fullname}})])]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.status,"color":"info","flat":"","false-value":false,"true-value":true},on:{"change":function($event){return _vm.updateStatus(item, $event)}}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }