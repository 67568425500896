<template>
    <v-card>
        <v-card-text>
            <v-data-table
                :headers="headers"
                :items="users"
                sort-by="name"
                :items-per-page="20"
                :footer-props="{
                    'items-per-page-options': [10, 20, 50, 100, 200],
                    'show-current-page': true,
                    'show-first-last-page': true,
                }"
            >
                <template v-slot:[`item.avatar`]="{ item }">
                    <v-avatar size="36">
                        <img :src="item.avatar" :alt="item.fullname" />
                    </v-avatar>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <v-switch
                        :input-value="item.status"
                        color="info"
                        flat
                        v-on:change="updateStatus(item, $event)"
                        :false-value="false"
                        :true-value="true"
                    ></v-switch>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'settings-users',
    data: () => ({}),
    created() {
        this.findUsers();
    },
    watch: {},
    computed: {
        ...mapGetters(['users']),
        headers() {
            return [
                {
                    text: '',
                    sortable: true,
                    value: 'avatar',
                    width: 36,
                },
                {
                    text: this.$t('users.fullname'),
                    sortable: true,
                    value: 'fullname',
                },
                {
                    text: this.$t('users.status'),
                    sortable: true,
                    value: 'status',
                },
            ];
        },
    },
    methods: {
        ...mapActions(['findUsers', 'updateUser']),
        updateStatus(user, status) {
            const userToUpdate = {
                id: user.id,
                status: status,
            };

            this.updateUser(userToUpdate);
        },
    },
};
</script>

<style></style>
